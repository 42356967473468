export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Página de Pagamento Seguro',
      description: 'Mantemos suas informações financeiras criptografadas com segurança',
      recommendation: 'Recomendação',
      cardNumber: 'Número do Cartão',
      cardName: 'Nome no cartão',
      cvv: 'CVV',
      expiredDate: 'Data de Validade',
      billingInfo: ' Informações de Cobrança',
      country: 'País',
      state: 'Estado',
      firstName: 'Primeiro Nome',
      lastName: 'Sobrenome',
      document: 'Documento',
      city: 'Cidade',
      address: 'Endereço',
      streetNumber: 'Número da Rua',
      zipCode: 'Código Postal',
      email: 'Email',
      phone: 'Número de Telefone',
      pay: 'PAGAR',
      choosePaymentMethod: 'Escolha o método de pagamento',
      continue: 'Continuar',
      later: 'Mais tarde',
      returnToMerchant: 'Cancelar e Retornar ao Comerciante',
      resultToMerchant: 'Retornar ao comerciante',
      orderId: 'ID do Pedido',
      orderAmount: ' Valor do Pedido',
      billingDescriptor: 'Descrição da Cobrança',
      goodsName: 'Nome dos Produtos',
      timeLimit: 'Por favor, complete o pagamento dentro do prazo, caso contrário, a página será encerrada',
      otherPaymentMethods: 'Outros métodos de pagamento',
      close: 'Fechar',
      paymentErrorTitle: 'Pagamento falhou',
      paymentErrorTip: 'Seu pagamento não foi processado com sucesso. Por favor, tente novamente.',
      paymentFailed: 'O processo de pagamento falhou',
      continueShopping: 'Continuar Comprando',
      paymentSuccess: 'O Processo de Pagamento foi concluído com sucesso',
      paymentPending: 'Pagamento enviado, confirmando',
      correctDateTip: 'Por favor, insira uma data de validade válida',
      correctCardNumberTip: 'Por favor, insira um número de cartão válido',
      correctSecurityCodeTip: 'Por favor, insira um código de segurança válido',
      mobileMoneyOperator: 'Operador de dinheiro Móvel',
      bank: 'Banco'
    },
    common: {
      uploadImgErrorTip: 'Só é permitido fazer upload de imagens',
      inputMessageTip: 'Por favor, preencha o conteúdo',
      sendSuccessTip: 'Enviado com sucesso',
      sendErrorTip: 'Falha no envio',
      inputPlaceholder: 'Por favor, insira {field}',
      selectPlaceholder: 'Por favor, selecione {field}'
    }
  }
};
