<template>
  <div>
    <a-layout>
      <a-layout-header>
        <div class="content">
          <div class="brand-wrapper">
            <img src="@/assets/logo.png" class="logo" />
            <span class="brand">{{ $t('message.title') }}</span>
          </div>
          <div>
           <!-- <Lang></Lang> -->
          </div>
        </div>
      </a-layout-header>
      <a-layout-content><router-view/></a-layout-content>
    </a-layout>
  </div>
</template>
<script>
// import Lang from '@/components/lang/index.vue';
export default {
  components: {
    // Lang
  }
};
</script>
<style lang="less">
@import '~@/styles/common.less';
.ant-layout-header {
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .brand-wrapper {
      display: flex;
      align-items: center;
    }
    .logo {
      width: 48px;
      height: 48px;
    }
    .brand {
      margin-left: 8px;
      font-size: 16px;
      color: @title-color;
    }
  }
}
</style>
