export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Page de paiement sécurisé',
      description: 'Nous gardons vos informations financières sécurisées et cryptées',
      recommendation: 'Recommandation',
      cardNumber: 'Numéro de carte',
      cardName: 'Nom sur la carte',
      cvv: 'CVV',
      expiredDate: 'Date d\'expiration',
      billingInfo: 'Informations de facturation',
      country: 'Pays',
      state: 'État',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      document: 'Document',
      city: 'Ville',
      address: 'Adresse',
      streetNumber: 'Numéro de rue',
      zipCode: 'Code postal',
      email: 'E-mail',
      phone: 'Numéro de téléphone',
      pay: 'PAYER',
      choosePaymentMethod: 'Choisissez le mode de paiement',
      continue: 'Continuer',
      later: 'Plus tard',
      returnToMerchant: 'Annuler et retourner au commerçant',
      resultToMerchant: 'Retourner au commerçant',
      orderId: 'ID de commande',
      orderAmount: 'Montant de la commande',
      billingDescriptor: 'Descripteur de facturation',
      goodsName: 'Nom du produit',
      timeLimit: 'Veuillez terminer le paiement dans le délai imparti, sinon la page expirera',
      otherPaymentMethods: 'Autres méthodes de paiement',
      close: 'Fermer',
      paymentErrorTitle: 'Échec du paiement',
      paymentErrorTip: 'Votre paiement n\'a pas été traité avec succès. Veuillez réessayer.',
      paymentFailed: 'Le processus de paiement a échoué',
      continueShopping: 'Continuer vos achats',
      paymentSuccess: 'Le processus de paiement a été réalisé avec succès',
      paymentPending: 'Paiement envoyé, en cours de confirmation',
      correctDateTip: 'Veuillez saisir une date d\'expiration valide',
      correctCardNumberTip: 'Veuillez saisir un numéro de carte valide',
      correctSecurityCodeTip: 'Veuillez entrer un code de sécurité valide',
      mobileMoneyOperator: 'Opérateur d\'argent mobile',
      bank: 'Banque'
    },
    common: {
      uploadImgErrorTip: 'Seules les images peuvent être téléchargées',
      inputMessageTip: 'Veuillez remplir le contenu',
      sendSuccessTip: 'Envoi réussi',
      sendErrorTip: 'Échec de l\'envoi',
      inputPlaceholder: 'Veuillez saisir {field}',
      selectPlaceholder: 'Veuillez sélectionner {field}'
    }
  }
};
