export default {
  namespaced: true,
  state: ({
    currentLan: 'en'
  }),
  mutations: {
    setLang: (state, lang) => {
      state.currentLan = lang;
    }
  },
  actions: {
    setLang: ({ commit }, lang) => {
      commit('setLang', lang);
    }
  }
};
