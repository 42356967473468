export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Sichere Zahlungsseite',
      description: 'Wir verschlüsseln Ihre Finanzinformationen sicher',
      recommendation: 'Empfehlung',
      cardNumber: 'Kartennummer',
      cardName: 'Name auf der Karte',
      cvv: 'CVV',
      expiredDate: 'Ablaufdatum',
      billingInfo: 'Rechnungsinformationen',
      country: 'Land',
      state: 'Bundesland',
      firstName: 'Vorname',
      lastName: 'Nachname',
      document: 'Dokument',
      city: 'Stadt',
      address: 'Adresse',
      streetNumber: 'Straßennummer',
      zipCode: 'Postleitzahl',
      email: 'E-Mail',
      phone: 'Telefonnummer',
      pay: 'ZAHLEN',
      choosePaymentMethod: 'Zahlungsmethode wählen',
      continue: 'Fortsetzen',
      later: 'Später',
      returnToMerchant: 'Abbrechen und zum Händler zurückkehren',
      resultToMerchant: 'Zum Händler zurückkehren',
      orderId: 'Bestell-ID',
      orderAmount: 'Bestellbetrag',
      billingDescriptor: 'Rechnungsbezeichnung',
      goodsName: 'Produktname',
      timeLimit: 'Bitte schließen Sie die Zahlung innerhalb des Zeitlimits ab, andernfalls wird die Seite zeitlich überschritten',
      otherPaymentMethods: 'Andere Zahlungsmethoden',
      close: 'Schließen',
      paymentErrorTitle: 'Zahlung fehlgeschlagen',
      paymentErrorTip: 'Ihre Zahlung wurde nicht erfolgreich verarbeitet. Bitte versuchen Sie es erneut.',
      paymentFailed: 'Zahlungsvorgang fehlgeschlagen',
      continueShopping: 'Weiter einkaufen',
      paymentSuccess: 'Zahlung erfolgreich abgeschlossen',
      paymentPending: 'Zahlung gesendet, Bestätigung läuft',
      correctDateTip: 'Bitte geben Sie ein gültiges Ablaufdatum ein',
      correctCardNumberTip: 'Bitte geben Sie eine gültige Kartennummer ein',
      correctSecurityCodeTip: 'Bitte geben Sie einen gültigen Sicherheitscode ein',
      mobileMoneyOperator: 'Mobile GeldOperator',
      bank: 'Bank'
    },
    common: {
      uploadImgErrorTip: 'Nur Bilder hochladen erlaubt',
      inputMessageTip: 'Bitte füllen Sie den Inhalt aus',
      sendSuccessTip: 'Senden erfolgreich',
      sendErrorTip: 'Senden fehlgeschlagen',
      inputPlaceholder: 'Bitte geben Sie {field} ein',
      selectPlaceholder: 'Bitte wählen Sie {field}'
    }
  }
};
