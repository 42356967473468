import { message } from 'ant-design-vue';
import axios from 'axios';

// axios 配置
const http = axios.create({
  baseURL: '/api',
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  transformRequest: [
    function formatData(data, headers) {
      if (headers['Content-Type'] === 'multipart/form-data') {
        return data;
      }
      return JSON.stringify(data);
    }
  ]
});

export function initHttpInterceptors() {
  // 请求拦截器
  http.interceptors.request.use(
    (config) => {
      // 开发环境下，如果请求是 post,put,patch,则打印数据体，方便调试
      if (process.env.NODE_ENV === 'development') {
        const { method } = config;
        if (method === 'post' || method === 'put' || method === 'patch') {
          console.log('config.data', config.data);
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // 响应拦截器
  http.interceptors.response.use(
    (res) => {
      // 112: token失效
      if (res.data.status === 200 || res.data.status === 112) {
        return res.data || res;
      }
      if (!/\/api\/checkout/.test(res.request.responseURL)) {
        message.error(res.data.message);
        return Promise.reject(res.data);
      }
      return Promise.resolve(res.data);
    },
    (error) => {
      if (error && error.response) {
        // switch (error.response.status) {
        // // 401 token失效
        // case 401:
        //   // router.push('/login');
        //   break;
        // // 403 服务器拒绝访问
        // case 403:
        //   // router.push('/error/403');
        //   break;
        // // 404 访问的资源不存在
        // case 404:
        //   // router.push('/error/404');
        //   break;
        // // 500 服务器错误
        // case 500:
        //   // router.push('/error/500');
        //   break;
        // default:
        //   // router.push('/error/404');
        //   break;
        // }
        // return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
}

export default http;
