export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Secure Payment page',
      description: 'We keep your financial information securely encrypted',
      recommendation: 'Recommendation',
      cardNumber: 'Card Number',
      cardName: 'Name on card',
      cvv: 'Security code',
      expiredDate: 'Expiration Date',
      billingInfo: 'Billing information',
      country: 'Country',
      state: 'State',
      firstName: 'First name',
      lastName: 'Last name',
      document: 'Document',
      city: 'City',
      address: 'Address',
      streetNumber: 'Street number',
      zipCode: 'Postcode',
      email: 'Email',
      phone: 'Phone number',
      pay: 'PAY',
      choosePaymentMethod: 'Choose payment method',
      continue: 'Continue',
      later: 'Later',
      returnToMerchant: 'Cancel and Return to Merchant',
      resultToMerchant: 'Return to merchant',
      orderId: 'Order ID',
      orderAmount: 'Order Amount',
      billingDescriptor: 'Billing Descriptor',
      goodsName: 'Goods name',
      timeLimit: 'Please compete payment within the time limit,otherwise the page will be timeout',
      otherPaymentMethods: 'Other payment methods',
      close: 'Close',
      paymentErrorTitle: 'Payment failed',
      paymentErrorTip: 'Your payment was not successfully processed.Please try again.',
      paymentFailed: 'Payment process has failed',
      continueShopping: 'Continue Shopping',
      paymentSuccess: 'Payment Process is done successfully',
      paymentPending: 'Payment sent, confirming',
      correctDateTip: 'Please enter a valid expiration date',
      correctCardNumberTip: 'Please enter a valid card number',
      correctSecurityCodeTip: 'Please enter a valid security code',
      mobileMoneyOperator: 'Mobile Money Operator',
      bank: 'Bank',
    },
    common: {
      uploadImgErrorTip: 'Only allow uploading files in image format',
      inputMessageTip: 'Please fill in the content',
      sendSuccessTip: 'Successfully sent',
      sendErrorTip: 'Fail in send',
      inputPlaceholder: 'please enter {field}',
      selectPlaceholder: 'please select {field}'
    }
  }
};
