export const Languages = [
  { value: 'en', label: 'English', text: 'en' }, // 英语
  { value: 'zh-cn', label: '中文', text: 'zh' },// 中文
  { value: 'pt', label: 'Português', text: 'pt' }, // 葡萄牙语
  { value: 'ru', label: 'Русский язык', text: 'ru' }, // 俄语
  { value: 'fr', label: 'Français', text: 'fr' }, // 法语
  { value: 'it', label: 'Italiano', text: 'it' }, // 意大利语
  { value: 'es', label: 'Español', text: 'es' }, // 西班牙语
  { value: 'nl', label: 'Nederlands', text: 'nl' }, // 荷兰语
  // { value: 'no', label: 'Norsk' }, // 挪威语
  { value: 'de', label: 'Deutsch', text: 'de' }, // 德语
  { value: 'pl', label: 'Polski', text: 'pl' }, // 波兰语
  { value: 'fi', label: 'Suomi', text: 'fi' }, // 芬兰语
  // { value: 'sv', label: 'svenska' }, // 瑞典语
  { value: 'ar', label: 'العربية', text: 'ar' }, // 阿拉伯语
  { value: 'th', label: 'ไทย', text: 'th' }, // 泰语
  { value: 'ja', label: 'にほんご', text: 'ja' }, // 日语
  // { value: 'zh-tw', label: '繁体中文' }, // 繁体中文
  { value: 'ko', label: '한국어', text: 'ko' }, // 韩语
];

