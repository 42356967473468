export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Veilige betalingspagina',
      description: 'We houden uw financiële informatie veilig versleuteld',
      recommendation: 'Aanbeveling',
      cardNumber: 'Kaartnummer',
      cardName: 'Naam op kaart',
      cvv: 'CVV',
      expiredDate: 'Vervaldatum',
      billingInfo: 'Factuurinformatie',
      country: 'Land',
      state: 'Staat',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      document: 'Document',
      city: 'Stad',
      address: 'Adres',
      streetNumber: 'Straatnummer',
      zipCode: 'Postcode',
      email: 'E-mail',
      phone: 'Telefoonnummer',
      pay: 'BETALEN',
      choosePaymentMethod: 'Kies betalingsmethode',
      continue: 'Doorgaan',
      later: 'Later',
      returnToMerchant: 'Annuleren en terugkeren naar handelaar',
      resultToMerchant: 'Terugkeren naar handelaar',
      orderId: 'Bestel-ID',
      orderAmount: 'Bestelbedrag',
      billingDescriptor: 'Factuurkenmerk',
      goodsName: 'Productnaam',
      timeLimit: 'Rond de betaling binnen de tijdslimiet af, anders verloopt de pagina',
      otherPaymentMethods: 'Andere betaalmethoden',
      close: 'Sluiten',
      paymentErrorTitle: 'Betaling mislukt',
      paymentErrorTip: 'Uw betaling is niet succesvol verwerkt. Probeer het opnieuw.',
      paymentFailed: 'Betalingsproces is mislukt',
      continueShopping: 'Doorgaan met winkelen',
      paymentSuccess: 'Betalingsproces is succesvol voltooid',
      paymentPending: 'Betaling verzonden, bevestiging in behandeling',
      correctDateTip: 'Voer een geldige vervaldatum in',
      correctCardNumberTip: 'Voer een geldig kaartnummer in',
      correctSecurityCodeTip: 'Voer een geldige beveiligingscode in',
      mobileMoneyOperator: 'Mobiel geldoperator',
      bank: 'Bank'
    },
    common: {
      uploadImgErrorTip: 'Alleen afbeeldingen kunnen worden geüpload',
      inputMessageTip: 'Vul de inhoud in',
      sendSuccessTip: 'Verzenden gelukt',
      sendErrorTip: 'Verzenden mislukt',
      inputPlaceholder: 'Vul {field} in',
      selectPlaceholder: 'Selecteer {field}'
    }
  }
};
