export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: '안전한 결제 페이지',
      description: '귀하의 금융 정보를 안전하게 암호화하여 보호합니다',
      recommendation: '추천',
      cardNumber: '카드 번호',
      cardName: '카드에 적힌 이름',
      cvv: 'CVV',
      expiredDate: '유효기간',
      billingInfo: '청구 정보',
      country: '국가',
      state: '주',
      firstName: '이름',
      lastName: '성',
      document: '문서',
      city: '도시',
      address: '주소',
      streetNumber: '도로 번호',
      zipCode: '우편 번호',
      email: '이메일',
      phone: '전화번호',
      pay: '결제하기',
      choosePaymentMethod: '결제 방법 선택',
      continue: '계속',
      later: '나중에',
      returnToMerchant: '취소하고 상인에게 돌아가기',
      resultToMerchant: '상인에게 돌아가기',
      orderId: '주문 ID',
      orderAmount: '주문 금액',
      billingDescriptor: '청구 설명',
      goodsName: '상품명',
      timeLimit: '제한 시간 내에 결제를 완료해 주십시오, 그렇지 않으면 페이지가 만료됩니다',
      otherPaymentMethods: '다른 결제 방법',
      close: '닫기',
      paymentErrorTitle: '결제 실패',
      paymentErrorTip: '결제가 성공적으로 처리되지 않았습니다. 다시 시도해 주세요.',
      paymentFailed: '결제 처리 실패',
      continueShopping: '쇼핑 계속하기',
      paymentSuccess: '결제 처리가 성공적으로 완료되었습니다',
      paymentPending: '결제 전송됨, 확인 중',
      correctDateTip: '유효한 만료일을 입력하세요.',
      correctCardNumberTip: '유효한 카드 번호를 입력하세요.',
      correctSecurityCodeTip: '유효한 보안 코드를 입력하세요.',
      mobileMoneyOperator: '모바일 머니 운영자',
      bank: '은행'
    },
    common: {
      uploadImgErrorTip: '이미지만 업로드할 수 있습니다.',
      inputMessageTip: '내용을 입력하세요.',
      sendSuccessTip: '전송 성공',
      sendErrorTip: '전송 실패',
      inputPlaceholder: '「{field}」를 입력하세요.',
      selectPlaceholder: '「{field}」를 선택하세요.'
    }
  }
};
